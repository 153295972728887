/* || General styles */
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}

@font-face {
  font-family: 'Roboto';
  src: url('res/fonts/Roboto/Roboto-Light.ttf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* || MUI Overrides */
/*.MuiTableBody-root > .MuiTableRow-root.Mui-selected{
  background-color: rgba(127, 219, 255, 0.2);
}*/


.MuiTableBody-root > .MuiTableRow-root.Mui-selected:hover{
  background-color: rgba(36, 178, 232, 0.75)
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #43a047 !important;
}
/*.MuiTableRow-root:nth-child(odd) {
  background-color: #f0f0f0;
}

.MuiTableBody-root > .MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #e0e0e0;
}


.MuiPaper-elevation10 > .MuiList-root > .MuiListItem-root:nth-child(4n+3){
  background-color: #eeeeee;
}

.MuiPaper-elevation10 > .MuiList-root > .MuiListItem-root:nth-child(4n+1){
  background-color: #ffffff;
}*/

/* || etc */
#root {
  height: 100%;
  display: flex;
}

#font-preloader {
  position: absolute;
  top: -600px;
  font-weight: 300; /* fontWeightRegular from theme */
  color: #000000; /* background.default from theme */
  font-family: "Roboto", "Arial", "Helvetica", sans-serif; /* fontFamily from theme */
}

#font-preloader > span {
  font-weight: 500; /* fontWeightMedium from theme */
}

#bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  /* background-color: #e0e0e0; background.default from theme */
  background-repeat:no-repeat;
  background-position: center center;
  background-size: auto 30%;
  color: black;
}

#loader {
  position: absolute;
  bottom: 8%;
  left: 50%;
  font-weight: 500;
  font-family: "Arial", "Helvetica", sans-serif; /* fontFamily from theme */
  color: black;
  transform: translate(-50%, -50%);
}

#loader > button {
  background: transparent;
  border: none;
  font-weight: inherit;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  color: #4682B4
}

/* Scrollbar width */
::-webkit-scrollbar {
  width: 4px;
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  background: #dbd9d9; 
}
 
/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

/* Scrollbar-Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
